var Airtable = require('airtable');
var base = new Airtable({ apiKey: process.env.AIRTABLE_KEY }).base(process.env.AIRTABLE_BASE);

// FORM
const form = document.querySelector('#form');
const name = document.querySelector('#name');
const email = document.querySelector('#email');
const consent = document.querySelector('#consent');
const marketing = document.querySelector('#marketing');

const alertSuccess = document.querySelector('#success');
const alertError = document.querySelector('#error-general');
const alertConsent = document.querySelector('#error-consent');

function showSuccess() {
  alertSuccess.classList.add('visible');
  alertError.classList.remove('visible');
  alertConsent.classList.remove('visible');
}

function showError() {
  alertSuccess.classList.remove('visible');
  alertError.classList.add('visible');
  alertConsent.classList.remove('visible');
}

function showConsent() {
  alertSuccess.classList.remove('visible');
  alertError.classList.remove('visible');
  alertConsent.classList.add('visible');
}

// handle form submit
form.addEventListener('submit', function (event) {
  event.preventDefault();

  var isConsent = consent.checked;

  if (isConsent) {
    base('web_form').create(
      [
        {
          fields: {
            name: name.value,
            email: email.value,
            consent: consent.checked,
            marketing: marketing.checked
          }
        }
      ],
      function (err, records) {
        // error
        if (err) {
          showError();
          console.error(err);
          return;
        }

        showSuccess();
        plausible('Vaccination form filled out');
        // success
        form.reset();
      }
    );
  } else {
    // show consent error
    showConsent();
  }
});
